import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupPerformanceByItemList from "./lists/performanceByItemList"
import {
  campaignPopupLoading,
  fetchCampaignPerformanceByItemAsync,
  resetCampaignPopupTable,
} from "../campaignPopupActions"
import RequestsService from "../../api/requestsService"

class CampaignPopupPerformanceByItem extends React.Component {
  componentDidMount() {
    this.getPerformanceByItem()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByItem()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCampaignPopupTable("performanceByItem"))
    RequestsService.cancelRequest("performance-by-item-request")
  }

  getPerformanceByItem = () => {
    RequestsService.cancelRequest("performance-by-item-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      fetchCampaignPerformanceByItemAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate
      )
    )
  }

  render() {
    let itemData = this.props.performanceByItem.slice(
      (this.props.currentPage - 1) * this.props.itemsPerPage,
      this.props.currentPage * this.props.itemsPerPage
    )
    return (
      <div className="performance-by-item">
        {this.props.isLoading ? (
          <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
            <CircularProgress className="loader" size={40} />
          </div>
        ) : (
          <CampaignPopupPerformanceByItemList performanceByItem={itemData} />
        )}
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    isLoading: state.campaignPopupV2.isLoading,
    performanceByItem: state.campaignPopupV2.performanceByItem.dataForTable,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByItem)
