class CampaignPopupActionTypes {}
CampaignPopupActionTypes.CAMPAIGN_POPUP_OPEN_V2 = "CAMPAIGN_POPUP_OPEN_V2"
CampaignPopupActionTypes.CAMPAIGN_POPUP_DATA_LOADED_V2 = "CAMPAIGN_POPUP_DATA_LOADED_V2"
CampaignPopupActionTypes.CAMPAIGN_POPUP_CLOSE_V2 = "CAMPAIGN_POPUP_CLOSE_V2"
CampaignPopupActionTypes.CAMPAIGN_POPUP_EDIT = "CAMPAIGN_POPUP_EDIT"
CampaignPopupActionTypes.CAMPAIGN_POPUP_DATES_SELECTED = "CAMPAIGN_POPUP_DATES_SELECTED"
CampaignPopupActionTypes.CAMPAIGN_POPUP_MAIN_SUB_SECTION = "CAMPAIGN_POPUP_MAIN_SUB_SECTION"
CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_DAY_RESPONSE_V2 =
  "CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_DAY_RESPONSE_V2"
CampaignPopupActionTypes.UPDATE_PERFORMANCE_BY_DAY_PAGE = "UPDATE_PERFORMANCE_BY_DAY_PAGE"
CampaignPopupActionTypes.CAMPAIGN_POPUP_SORT_PERFORMANCE_LIST = "CAMPAIGN_POPUP_SORT_PERFORMANCE_LIST"
CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_BID_HISTORY_RESPONSE =
  "CAMPAIGN_POPUP_FETCH_CAMPAIGN_BID_HISTORY_RESPONSE"
CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SORT_BY = "CAMPAIGN_POPUP_BID_HISTORY_SORT_BY"
CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SHOW_MORE = "CAMPAIGN_POPUP_BID_HISTORY_SHOW_MORE"
CampaignPopupActionTypes.CAMPAIGN_POPUP_LOADING_V2 = "CAMPAIGN_POPUP_LOADING_V2"
CampaignPopupActionTypes.UPDATE_USER_CONFIRMATION_BEFORE_LEAVING = "UPDATE_VALIDATE_BEFORE_LEAVING"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_SUB_SOURCE_RESPONSE_V2 =
  "FETCH_CAMPAIGN_PERFORMANCE_BY_SUB_SOURCE_RESPONSE_V2"
CampaignPopupActionTypes.UPDATE_CAMPAIGN_POPUP_TABLE_PAGE = "UPDATE_CAMPAIGN_POPUP_TABLE_PAGE"
CampaignPopupActionTypes.RESET_CAMPAIGN_POPUP_TABLE = "RESET_CAMPAIGN_POPUP_TABLE"
CampaignPopupActionTypes.CAMPAIGN_POPUP_IS_LOADING_EXPORT_PERFORMANCE_BY_DATE =
  "CAMPAIGN_POPUP_IS_LOADING_EXPORT_PERFORMANCE_BY_DATE"
CampaignPopupActionTypes.CAMPAIGN_POPUP_IS_LOADING_EXPORT_BID_HISTORY = "CAMPAIGN_POPUP_IS_LOADING_EXPORT_BID_HISTORY"
CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS = "UPDATE_CAMPAIGN_TAGS_V2"
CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_HOUR_RESPONSE_V2 =
  "CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_HOUR_RESPONSE_V2"
CampaignPopupActionTypes.CAMPAIGN_POPUP_RESET_DATES_V2 = "CAMPAIGN_POPUP_RESET_DATES_V2"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_COUNTRY_RESPONSE =
  "FETCH_CAMPAIGN_PERFORMANCE_BY_COUNTRY_RESPONSE"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_DEVICE_RESPONSE = "FETCH_CAMPAIGN_PERFORMANCE_BY_DEVICE_RESPONSE"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_BROWSER_RESPONSE =
  "FETCH_CAMPAIGN_PERFORMANCE_BY_BROWSER_RESPONSE"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_FUNNEL_RESPONSE = "FETCH_CAMPAIGN_PERFORMANCE_BY_FUNNEL_RESPONSE"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_KEYWORD_RESPONSE =
  "FETCH_CAMPAIGN_PERFORMANCE_BY_KEYWORD_RESPONSE"
CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_ITEM_RESPONSE = "FETCH_CAMPAIGN_PERFORMANCE_BY_ITEM_RESPONSE"
export default CampaignPopupActionTypes
