import axios from "axios"
import Consts from "../app/consts"
import RequestsService from "./requestsService"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import { isNullOrUndefined } from "../utils/funcUtils"

export default class CampaignServiceV2 {
  static getCampaignsReport(networkCode, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaigns_report", {
      cancelToken: RequestsService.cancelToken("campaigns-list-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
        network_code: networkCode,
      },
    })
  }

  static getAllUnintegratedCampaigns(networkCode, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/unintegrated_campaigns_report", {
      cancelToken: RequestsService.cancelToken("campaigns-list-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
        network_code: networkCode,
      },
    })
  }

  static pauseCampaign(campaignId) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/" + campaignId + "/pause")
  }

  static activeCampaign(campaignId) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/" + campaignId + "/active")
  }

  static bulkStopCampaigns(campaigns) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaigns_bulk_management/bulk_pause", { campaigns })
  }

  static bulkStartCampaigns(campaigns) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaigns_bulk_management/bulk_active", { campaigns })
  }

  static getCampaignPerformanceByDay(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/by_day", {
      cancelToken: RequestsService.cancelToken("performance-by-day-request"),
      params: {
        from_datetime: isNullOrUndefined(fromDate) ? null : fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: isNullOrUndefined(toDate) ? null : toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceBySubSource(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/sub_source", {
      cancelToken: RequestsService.cancelToken("performance-by-sub-source-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByCountry(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/country", {
      cancelToken: RequestsService.cancelToken("performance-by-country-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByItem(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/item", {
      cancelToken: RequestsService.cancelToken("performance-by-item-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByFunnel(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/funnel", {
      cancelToken: RequestsService.cancelToken("performance-by-funnel-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByKeyword(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/keyword", {
      cancelToken: RequestsService.cancelToken("performance-by-keyword-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByDevice(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/device", {
      cancelToken: RequestsService.cancelToken("performance-by-device-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignPerformanceByBrowser(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/browser", {
      cancelToken: RequestsService.cancelToken("performance-by-browser-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getCampaignBidHistory(campaignId, fromDate, toDate) {
    return axios.get(
      Consts.BASE_API_URL + "/api/campaigns_attribution/campaign_management/" + campaignId + "/bid_history",
      {
        cancelToken: RequestsService.cancelToken("bid-history-request"),
        params: {
          from_date: fromDate.format(Consts.SERVER_DATE_FORMAT),
          to_date: toDate.format(Consts.SERVER_DATE_FORMAT),
        },
      }
    )
  }

  static changeBid(campaignId, currentBid, desiredBid) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/" + campaignId + "/bid", {
      bid: parseFloat(desiredBid.toFixed(3)),
      current_bid: parseFloat(currentBid.toFixed(4)), // Sending an extra digit on current_bid in order to prevent rounding issues for campaigns with different currencies
    })
  }

  static changeRoas(campaignId, currentRoas, desiredRoas) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/" + campaignId + "/roas", {
      roas: parseInt(desiredRoas.toFixed(0)),
      current_roas: currentRoas,
    })
  }

  static getSingleCampaign(campaign) {
    return axios.get(Consts.BASE_API_URL + "/api/campaigns_attribution/campaign/" + campaign.campaign_id + "/data", {
      cancelToken: RequestsService.cancelToken("campaign-data-request"),
    })
  }

  static editCampaignV2(
    campaignId,
    { name = null, daily_budget = null, daily_budget_usd = null, total_budget = null, total_budget_usd = null } = {}
  ) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/" + campaignId + "/daily_budget", {
      name,
      daily_budget: daily_budget ? parseFloat(daily_budget.toFixed(0)) : null,
      daily_budget_usd: daily_budget_usd ? parseFloat(daily_budget_usd.toFixed(0)) : null,
      total_budget: total_budget ? parseFloat(total_budget.toFixed(0)) : null,
      total_budget_usd: total_budget_usd ? parseFloat(total_budget_usd.toFixed(0)) : null,
    })
  }

  static bulkChangeCampaignsBudget(campaigns, action, value, operation) {
    return axios.post(
      Consts.BASE_API_URL + "/api/integrations/campaigns_bulk_management/bulk_edit_campaigns_budget_v2",
      {
        campaigns,
        edit_params: {
          value,
          is_by_fixed: action === "BudgetFixedAmount",
          is_by_percent: action === "BudgetAdjustByPercent",
          is_by_amount: action === "BudgetAdjustByAmount",
          operation,
        },
      }
    )
  }

  static bulkChangeBid(campaigns) {
    campaigns = campaigns.map((campaign) => {
      return {
        key: campaign.key,
        campaign_id: campaign.campaign_id,
        bid: campaign.newBid,
        current_bid: campaign.bid,
      }
    })

    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaigns_bulk_management/bulk_change_bid_v2", {
      campaigns,
    })
  }

  static bulkAddTags(campaigns, tags) {
    let campaignIds = campaigns.map((campaign) => campaign.campaign_id)

    return axios.post(Consts.BASE_API_URL + "/api/tags_management/bulk_add_tags", {
      campaign_ids: campaignIds,
      tags,
    })
  }

  static bulkRemoveTags(campaigns, tags) {
    let campaignIds = campaigns.map((campaign) => campaign.campaign_id)

    return axios.post(Consts.BASE_API_URL + "/api/tags_management/bulk_remove_tags", {
      campaign_ids: campaignIds,
      tags,
    })
  }

  static getNetworkTags(networkCode) {
    return axios.get(Consts.BASE_API_URL + "/api/tags_management/" + networkCode, {
      cancelToken: RequestsService.cancelToken("get-network-tags"),
    })
  }

  static addCampaignTag(campaignId, tag) {
    return axios.post(
      Consts.BASE_API_URL + "/api/tags_management/" + campaignId + "/add_tag",
      {
        tag: tag,
      },
      {
        cancelToken: RequestsService.cancelToken("update-campaign-tags"),
      }
    )
  }

  static removeCampaignTag(campaignId, tag) {
    return axios.post(
      Consts.BASE_API_URL + "/api/tags_management/" + campaignId + "/remove_tag",
      {
        tag: tag,
      },
      {
        cancelToken: RequestsService.cancelToken("update-campaign-tags"),
      }
    )
  }

  static getCampaignPerformanceByHour(campaignId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/campaign/performance/" + campaignId + "/by_hour", {
      cancelToken: RequestsService.cancelToken("performance-by-hour-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
      },
    })
  }

  static getConversionEvents() {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/custom_conversion_events")
  }

  static bulkActivateCampaigns(campaignsToActivationData) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaigns_bulk_management/bulk_smart_activate", {
      campaignsToActivationData,
    })
  }

  static getDashboard(networkCode, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/dashboard_report", {
      cancelToken: RequestsService.cancelToken("dashboard-request"),
      params: {
        from_datetime: fromDate.format(Consts.SERVER_DATETIME_FORMAT),
        to_datetime: toDate.format(Consts.SERVER_DATETIME_FORMAT),
        network_code: networkCode,
      },
    })
  }
}
