import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import {
  campaignPopupClose,
  campaignPopupDatesSelected,
  campaignPopupMainSubSection,
  updateUserConfirmationBeforeLeaving,
} from "../campaignPopupActions"
import DatePicker from "../../common/components/datePickerV2"
import CampaignOptionsMenu from "../../campaignsV2/components/campaignOptionsMenu"
import CampaignBidPopup from "../../campaignsV2/components/campaignBidComponents/campaignBidPopup"
import StepperPopup from "../../common/components/stepperPopup"
import CampaignActionsMenu from "../../campaignsV2/components/campaignActionsMenu"
import { getCampaignCurrency, getPlatformText, isUnknownCampaignTrafficSource } from "../../utils/campaignUtilsV2"
import { getCountryTextV2 } from "../../utils/countryUtils"
import { changeDailyBudgetAsync, changeTotalBudgetAsync, filterCampaigns } from "../../campaignsV2/campaignActions"
import AuthApi from "../../api/authService"
import ModuleTypes from "../../common/consts/moduleTypes"
import Consts from "../../app/consts"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"
import { eventsTracker } from "../../api/eventsTracker"
import CampaignTags from "./campaignTags"
import OpenLinkIcon from "../../resources/svgs/OpenLinkIcon.svg"
import { Filters } from "../../common/consts/filterTypesV2"
import { getLangNameFromCode } from "language-name-map"
import { isNullOrUndefined } from "../../utils/funcUtils"
import AdTypes from "../../common/consts/adTypes"
import { getArticleTypeTag } from "../../utils/articleUtils"
import articlesTypeConsts from "../../articles/components/articlesType"

class CampaignPopupMainTop extends React.Component {
  constructor(props) {
    super(props)
    this.isAllowedToChangeBudget = AuthApi.hasModule(ModuleTypes.EDIT_CAMPAIGN)
    this.isRsocCampaign = this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name
    this.isEcomCampaign = this.props.campaign.post_style === articlesTypeConsts.affiliation.name
  }

  switchMainSubSection = (subSection) => {
    if (this.props.mainSubSection !== subSection) {
      let userConfirmed = true

      if (this.props.userConfirmationBeforeLeaving) {
        userConfirmed = window.confirm("Are you sure? you will lose your unsaved changes")
      }

      if (userConfirmed) {
        this.props.dispatch(campaignPopupMainSubSection(subSection))
        eventsTracker.trackCampaignPopupChangePage(this.props.campaign.tracking_code, subSection)
        this.props.dispatch(updateUserConfirmationBeforeLeaving(false))
      }
    }
  }

  onDailyBudgetChanged = (newDailyBudget, newDailyBudgetInUSD = newDailyBudget) => {
    this.props.dispatch(changeDailyBudgetAsync(this.props.campaign, newDailyBudget, newDailyBudgetInUSD))
  }

  onTotalBudgetChanged = (newTotalBudget, newTotalBudgetInUSD = newTotalBudget) => {
    this.props.dispatch(changeTotalBudgetAsync(this.props.campaign, newTotalBudget, newTotalBudgetInUSD))
  }

  addCampaignUrlFilter = () => {
    let url = this.props.campaign.trimmedUrl
    let newFiltersMap = new Map(this.props.filters)
    let filterByType = newFiltersMap.get(Filters.ARTICLE_LINK.filterType) || {
      includeFilters: [],
      excludeFilters: [],
    }

    let foundSameFilter = false
    let filterItems = filterByType.includeFilters

    filterItems.forEach((filter) => {
      if (filter.filterValue[0] === url) {
        foundSameFilter = true
      }
    })

    if (!foundSameFilter) {
      filterItems.push(
        Object.assign({}, Filters.ARTICLE_LINK, {
          filterName: url,
          filterValue: [url],
          filterOperator: Filters.ARTICLE_LINK.defaultOperator,
          isExclude: false,
        })
      )
    }

    newFiltersMap.set(Filters.ARTICLE_LINK.filterType, filterByType)
    this.props.dispatch(filterCampaigns(newFiltersMap))
    this.props.dispatch(campaignPopupClose())
  }

  render() {
    let dailyBudget = null
    let totalBudget = null
    let informationBar = null
    let providerCreatedAt = null
    let providerCreatedBy = null
    let safetyRating = null
    let urlLine = null
    let isUnknownTrafficSource = isUnknownCampaignTrafficSource(this.props.campaign)

    if (this.props.campaign.provider_created_at) {
      providerCreatedAt = Moment(this.props.campaign.provider_created_at, Consts.SERVER_DATETIME_FORMAT).format(
        Consts.CLIENT_SHORT_DATE_FORMAT
      )
      providerCreatedAt = "Created " + providerCreatedAt
    }

    if (this.props.campaign.additional_info && this.props.campaign.additional_info.user_name) {
      providerCreatedBy = "By " + this.props.campaign.additional_info.user_name
    }

    if (this.props.campaign.provider_additional_info && this.props.campaign.provider_additional_info.safety_rating) {
      safetyRating = CampaignsConsts.TABOOLA_SAFETY_LEVELS[this.props.campaign.provider_additional_info.safety_rating]
    }

    if (this.props.campaign.daily_budget && !isUnknownTrafficSource) {
      dailyBudget = (
        <li>
          <span className="budget-title">Daily budget</span>
          <StepperPopup
            startingValue={this.props.campaign.daily_budget}
            currency={getCampaignCurrency()}
            isAllowedToChange={
              !Consts.PROVIDERS_THAT_CANT_CHANGE_BUDGET.includes(this.props.campaign.provider_id) &&
              this.isAllowedToChangeBudget
            }
            campaign={this.props.campaign}
            stepSize={50}
            isLoading={this.props.campaign.isCampaignDailyBudgetLoading}
            onSubmitClicked={(value, valueInUSD) => this.onDailyBudgetChanged(value, valueInUSD)}
          />
        </li>
      )
    }

    if (
      this.props.campaign.total_budget &&
      Consts.PROVIDERS_WITH_TOTAL_BUDGET.includes(this.props.campaign.provider_id) &&
      !isUnknownTrafficSource
    ) {
      totalBudget = (
        <li>
          <span className="budget-title">Total budget</span>
          <StepperPopup
            startingValue={this.props.campaign.total_budget}
            currency={getCampaignCurrency()}
            isAllowedToChange={
              !Consts.PROVIDERS_THAT_CANT_CHANGE_BUDGET.includes(this.props.campaign.provider_id) &&
              this.isAllowedToChangeBudget
            }
            stepSize={50}
            campaign={this.props.campaign}
            isLoading={this.props.campaign.isCampaignTotalBudgetLoading}
            onSubmitClicked={(value, valueInUSD) => this.onTotalBudgetChanged(value, valueInUSD)}
          />
        </li>
      )
    }

    if (!isUnknownTrafficSource) {
      informationBar = (
        <>
          <div>
            <li>
              Current Bid
              <CampaignBidPopup campaign={this.props.campaign} />
            </li>
          </div>
          <div className="separator" />
          <div>
            {dailyBudget}
            {totalBudget}
          </div>
        </>
      )

      urlLine = (
        <div className="d-flex align-items-center">
          <a href={this.props.campaign.trimmedUrl} target="_blank" className="article-link clickable">
            {this.props.campaign.trimmedUrl}
            <OpenLinkIcon className="article-link-icon" />
          </a>
          <div className="view-campaigns clickable" onClick={this.addCampaignUrlFilter}>
            (View campaigns)
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="campaign-popup-main-top">
          <div className="d-flex justify-content-between">
            <div className="campaign-title">{this.props.campaign.tracking_code}</div>
            <div className="campaign-datepicker d-flex align-items-center">
              <DatePicker
                startDate={this.props.datePickerDates.startDate}
                endDate={this.props.datePickerDates.endDate}
                showLastHour={this.props.datePickerDates.showLastHour}
                dateType={this.props.datePickerDates.dateType}
                allowLastHour={false}
                allowLast3Hours={false}
                allowSevenDays={true}
                allowAllTime={true}
                allowToday={true}
                onDatesSelected={(startDate, endDate, dateType, showLastHour) => {
                  this.props.dispatch(campaignPopupDatesSelected(startDate, endDate, dateType, showLastHour))
                }}
              />
              <div className="campaign-options-menu-button d-flex align-items-center justify-content-center clickable">
                <CampaignOptionsMenu
                  campaign={this.props.campaign}
                  sourceComponent={"Campaign popup"}
                  showExportButton={true}
                />
              </div>
            </div>
          </div>
          {urlLine}
          <div className="d-flex flex-column">
            <ul className="d-flex flex-row campaign-details-list">
              <li>
                <CampaignActionsMenu statusTextVisible={true} campaign={this.props.campaign} />
              </li>
              {informationBar}
            </ul>
            <CampaignTags
              campaign={this.props.campaign}
              constantTags={[
                isNullOrUndefined(this.props.campaign?.conversion_name) || this.props.campaign.conversion_name === "--"
                  ? null
                  : this.props.campaign.conversion_name,
                this.props.campaign.device_platforms ? getPlatformText(this.props.campaign.device_platforms) : null,
                isNullOrUndefined(this.props.campaign?.country_group_name)
                  ? getCountryTextV2(this.props.campaign.countries)
                  : this.props.campaign?.country_group_name,
                this.props.campaign.targeting?.locales
                  ? getLangNameFromCode(this.props.campaign.targeting.locales[0])?.name
                  : null,
                getArticleTypeTag(this.props.campaign.post_style),
                providerCreatedBy,
                providerCreatedAt,
                safetyRating,
                isNullOrUndefined(this.props.campaign?.ad_type)
                  ? null
                  : AdTypes.idToType[this.props.campaign.ad_type]?.name,
              ]}
              dynamicTags={this.props.campaign.tags ? this.props.campaign.tags : []}
            />
          </div>
        </div>
        <ul className="campaigns-popup-sections-switcher">
          <li>Performance by: </li>
          <li
            className={"clickable " + (this.props.mainSubSection === "Day" ? "selected" : "")}
            onClick={() => this.switchMainSubSection("Day")}
          >
            Day
          </li>

          {/* Performance by hour supported only for predicted report */}
          <li
            className={"clickable" + (this.props.mainSubSection === "Hour" ? " selected" : "")}
            onClick={() => this.switchMainSubSection("Hour")}
          >
            Hour
          </li>

          {/* Performance by subsource supported only for Taboola & Outbrain */}
          <li
            className={"clickable" + (this.props.mainSubSection === "SubSources" ? " selected" : "")}
            onClick={() => this.switchMainSubSection("SubSources")}
          >
            Sub-Source
          </li>

          <li
            className={"clickable" + (this.props.mainSubSection === "Country" ? " selected" : "")}
            onClick={() => this.switchMainSubSection("Country")}
          >
            Country
          </li>

          {this.isRsocCampaign ? (
            <>
              <li
                className={"clickable" + (this.props.mainSubSection === "Funnel" ? " selected" : "")}
                onClick={() => this.switchMainSubSection("Funnel")}
              >
                Funnel
              </li>
              <li
                className={"clickable" + (this.props.mainSubSection === "Keyword" ? " selected" : "")}
                onClick={() => this.switchMainSubSection("Keyword")}
              >
                Keyword
              </li>
            </>
          ) : null}
          {this.isEcomCampaign ? (
            <li
              className={"clickable" + (this.props.mainSubSection === "Item" ? " selected" : "")}
              onClick={() => this.switchMainSubSection("Item")}
            >
              Item
            </li>
          ) : null}

          <li
            className={"clickable" + (this.props.mainSubSection === "Browser" ? " selected" : "")}
            onClick={() => this.switchMainSubSection("Browser")}
          >
            Browser
          </li>

          <li
            className={"clickable" + (this.props.mainSubSection === "Device" ? " selected" : "")}
            onClick={() => this.switchMainSubSection("Device")}
          >
            Device
          </li>

          <li
            className={
              "aligned-right clickable" +
              (isUnknownTrafficSource ? " disabled " : "") +
              (this.props.mainSubSection === "BidHistory" ? " selected" : "")
            }
            onClick={() => this.switchMainSubSection("BidHistory")}
          >
            Campaign activity
          </li>

          {/* only remove from screen - until Tracker data is available per page */}
          {/* <li className={"clickable disabled" + (this.props.mainSubSection === 'Pages' ? 'selected' : '')}
              onClick={() => this.switchMainSubSection('Pages')}>Pages</li> */}
        </ul>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isOpen: state.campaignPopupV2.isOpen,
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
      showLastHour: state.campaignPopupV2.datePickerDates.showLastHour,
      dateType: state.campaignPopupV2.datePickerDates.dateType,
    },
    providers: state.app.providers,
    mainSubSection: state.campaignPopupV2.mainSubSection,
    userConfirmationBeforeLeaving: state.campaignPopupV2.userConfirmationBeforeLeaving,
  }
}

export default connect(mapStateToProps)(CampaignPopupMainTop)
