import CampaignServiceV2 from "../api/campaignServiceV2"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import { notificationAdd } from "../common/actions/commonActions"
import RequestsService from "../api/requestsService"
import { history } from "../app/store"
import CampaignPopupActionTypes from "./campaignPopupActionTypes"

export function campaignPopupOpen(campaignData = null, forceTab = null) {
  window.Intercom("trackEvent", "campaign-popup-opened")

  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_OPEN_V2, campaignData, forceTab }
}

export function campaignPopupDataLoaded(campaignData) {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_DATA_LOADED_V2, campaignData }
}

export function campaignPopupClose(returnToPreviousPage = true) {
  RequestsService.cancelRequest("campaign-data-request")

  if (returnToPreviousPage) {
    let locationState = history.getCurrentLocation().state

    if (locationState && locationState.returnTo) {
      // Returning the user to the route he was on before opening the campaign popup, we also push a location state
      // object to indicate that we're returning from the campaign popup in order to prevent the window scroll reset
      // that is being made by the router.
      history.push({ pathname: locationState.returnTo, state: { isReturningFromCampaignPopup: true } })
    } else {
      // If there's no location state, it means that the user browsed directly to the campaign popup page and now we
      // must send him back to his default page.
      // We want the user to be able to click "back" in the browser to browse back to the campaign popup page, so we're
      // pushing a new state '/' and then the router will take care of where he needs to be.
      history.push("/")
    }
  }

  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_CLOSE_V2 }
}

export function campaignPopupEdit(campaign) {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_EDIT, campaign }
}

export function campaignPopupMainSubSection(subSection) {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_MAIN_SUB_SECTION, subSection }
}

export function campaignPopupDatesSelected(startDate, endDate, dateType, showLastHour) {
  return {
    type: CampaignPopupActionTypes.CAMPAIGN_POPUP_DATES_SELECTED,
    startDate,
    endDate,
    dateType,
    showLastHour,
  }
}

export function campaignPopupFetchCampaignPerformanceByDayResponse(performanceByDay, store) {
  return {
    type: CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_DAY_RESPONSE_V2,
    performanceByDay,
    isLoading: false,
    store,
  }
}

export function updatePerformanceByDayPopupPage(currentPage) {
  return { type: CampaignPopupActionTypes.UPDATE_PERFORMANCE_BY_DAY_PAGE, currentPage }
}

export function fetchCampaignPerformanceBySubSourceResponse(performanceBySubSource) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_SUB_SOURCE_RESPONSE_V2, performanceBySubSource }
}

export function fetchCampaignPerformanceByCountryResponse(performanceByCountry) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_COUNTRY_RESPONSE, performanceByCountry }
}

export function fetchCampaignPerformanceByItemResponse(performanceByItem) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_ITEM_RESPONSE, performanceByItem }
}

export function fetchCampaignPerformanceByFunnelResponse(performanceByFunnel) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_FUNNEL_RESPONSE, performanceByFunnel }
}
export function fetchCampaignPerformanceByKeywordResponse(performanceByKeyword, summaryRow, totelPerDay) {
  return {
    type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_KEYWORD_RESPONSE,
    performanceByKeyword,
    summaryRow,
    totelPerDay,
  }
}

export function fetchCampaignPerformanceByDeviceResponse(performanceByDevice) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_DEVICE_RESPONSE, performanceByDevice }
}

export function fetchCampaignPerformanceByBrowserResponse(performanceByBrowser) {
  return { type: CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_BROWSER_RESPONSE, performanceByBrowser }
}

export function campaignPopupSortPerformanceList(sortBy, performanceListName) {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_SORT_PERFORMANCE_LIST, sortBy, performanceListName }
}

export function campaignPopupFetchCampaignBidHistoryResponse(bidHistory, firstRequest, reduxStore) {
  return {
    type: CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_BID_HISTORY_RESPONSE,
    isLoading: false,
    firstRequest,
    bidHistory,
    reduxStore,
  }
}

export function campaignPopupBidHistorySortBy(sortBy) {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SORT_BY, sortBy }
}

export function campaignPopupBidHistoryShowMore() {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SHOW_MORE }
}

export function updateUserConfirmationBeforeLeaving(userConfirmationBeforeLeaving) {
  return { type: CampaignPopupActionTypes.UPDATE_USER_CONFIRMATION_BEFORE_LEAVING, userConfirmationBeforeLeaving }
}

export function campaignPopupLoading() {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_LOADING_V2, isLoading: true }
}

export function updateCampaignPopupTablePage(currentPage, tableName) {
  return { type: CampaignPopupActionTypes.UPDATE_CAMPAIGN_POPUP_TABLE_PAGE, tableName, currentPage }
}

export function resetCampaignPopupTable(tableName) {
  return { type: CampaignPopupActionTypes.RESET_CAMPAIGN_POPUP_TABLE, tableName }
}

export function campaignPopupFetchCampaignPerformanceByDayAsync(campaign, fromDate, toDate) {
  return (dispatch, getState) => {
    RequestsService.cancelRequest("performance-by-day-request")
    return CampaignServiceV2.getCampaignPerformanceByDay(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(campaignPopupFetchCampaignPerformanceByDayResponse(result.data.report, getState()))
      })
      .catch((error) => {
        if (error) {
          dispatch(campaignPopupFetchCampaignPerformanceByDayResponse([], getState()))
          dispatch(notificationAdd(CampaignsConsts.CAMPAIGN_REPORT_ERROR_MESSAGE))
        }
      })
  }
}

export function fetchCampaignPerformanceBySubSourceAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceBySubSource(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceBySubSourceResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceBySubSourceResponse([]))
        }
      })
  }
}

export function fetchCampaignPerformanceByCountryAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByCountry(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceByCountryResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByCountryResponse([]))
        }
      })
  }
}

export function fetchCampaignPerformanceByItemAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByItem(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceByItemResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByItemResponse([]))
        }
      })
  }
}

export function fetchCampaignPerformanceByFunnelAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByFunnel(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceByFunnelResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByFunnelResponse([]))
        }
      })
  }
}

export function fetchCampaignPerformanceByDeviceAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByDevice(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceByDeviceResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByDeviceResponse([]))
        }
      })
  }
}

export function fetchCampaignPerformanceByKeywordAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByKeyword(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(
          fetchCampaignPerformanceByKeywordResponse(result.data.report, result.data.summaryRow, result.data.totelPerDay)
        )
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByKeywordResponse([], {}, {}))
        }
      })
  }
}

export function fetchCampaignPerformanceByBrowserAsync(campaign, fromDate, toDate) {
  return (dispatch) => {
    return CampaignServiceV2.getCampaignPerformanceByBrowser(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(fetchCampaignPerformanceByBrowserResponse(result.data.report))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignPerformanceByBrowserResponse([]))
        }
      })
  }
}

export function campaignPopupFetchCampaignBidHistoryAsync(campaign, fromDate, toDate, firstRequest) {
  return (dispatch, getState) => {
    return CampaignServiceV2.getCampaignBidHistory(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(
          campaignPopupFetchCampaignBidHistoryResponse(result.data.bid_log_changes, firstRequest, getState())
        )
      })
      .catch((error) => {
        if (error) {
          dispatch(campaignPopupFetchCampaignBidHistoryResponse([], false, getState()))
          dispatch(notificationAdd(CampaignsConsts.CAMPAIGN_REPORT_ERROR_MESSAGE))
        }
      })
  }
}

export function campaignPopupCancelRequest(requestCancelToken) {
  RequestsService.cancelRequest(requestCancelToken)
}

export function addCampaignTag(campaign, currentCampaignTags, newCampaignTags, newAddedTag) {
  return (dispatch) => {
    RequestsService.cancelRequest("update-campaign-tags")
    dispatch({ type: CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS, campaignTags: newCampaignTags, campaign })
    CampaignServiceV2.addCampaignTag(campaign.campaign_id, newAddedTag).catch((error) => {
      // Getting into the 'catch' without any error means that the request was canceled
      if (error) {
        dispatch({ type: CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS, campaignTags: currentCampaignTags, campaign })
      }
    })
  }
}

export function removeCampaignTag(campaign, currentCampaignTags, newCampaignTags, removedTag) {
  return (dispatch) => {
    RequestsService.cancelRequest("update-campaign-tags")
    dispatch({ type: CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS, campaignTags: newCampaignTags, campaign })
    CampaignServiceV2.removeCampaignTag(campaign.campaign_id, removedTag).catch((error) => {
      // Getting into the 'catch' without any error means that the request was canceled
      if (error) {
        dispatch({ type: CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS, campaignTags: currentCampaignTags, campaign })
      }
    })
  }
}

export function campaignPopupFetchCampaignPerformanceByHourResponse(report, store) {
  return {
    type: CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_HOUR_RESPONSE_V2,
    performanceByHour: report.performance_by_hour,
    isLoading: false,
    store,
  }
}

export function campaignPopupFetchCampaignPerformanceByHourAsync(campaign, fromDate, toDate) {
  return (dispatch, getState) => {
    RequestsService.cancelRequest("performance-by-hour-request")
    CampaignServiceV2.getCampaignPerformanceByHour(campaign.campaign_id, fromDate, toDate)
      .then((result) => {
        return dispatch(campaignPopupFetchCampaignPerformanceByHourResponse(result.data, getState()))
      })
      .catch((error) => {
        if (error) {
          dispatch(campaignPopupFetchCampaignPerformanceByHourResponse({ performance_by_hour: [] }), getState())
          dispatch(notificationAdd(CampaignsConsts.CAMPAIGN_REPORT_ERROR_MESSAGE))
        }
      })
  }
}

export function resetCampaignPopupDates() {
  return { type: CampaignPopupActionTypes.CAMPAIGN_POPUP_RESET_DATES_V2 }
}
